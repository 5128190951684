
import Vue from "vue";

import { LOAD_CACHE_RESOURCES } from "@/store/actions-types";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "Index",
  components: {
    AppBar: () => import("@/components/core/AppBar.vue"),
    Drawer: () => import("@/components/core/Drawer.vue"),
    ViewContent: () => import("@/components/core/View.vue"),
    Snackbar: () => import("@/components/core/Snackbar.vue"),
  },
  mounted() {
    if (!this.user.isAnonymous) {
      this.$store.dispatch(LOAD_CACHE_RESOURCES);
    }
  },
  computed: {
    ...mapGetters({
      user: "getLoggedUser",
    }),
  },
});
